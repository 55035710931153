export const discordTheme = {
    backgroundColor: "#23272A",
    textColor: "#ffffff",
    btnTxtColor: "#ffffff",
    primary: "#7289DA",
    containerColor: "#2C2F33",
    mainPanelColor: "#36393F",
    inputBg: "#40444B",
    inputSpan: "rgba(255, 255, 255, 0.2)",
    inputTxtColor: "#ffffff"
}

export const slackTheme = {
    backgroundColor: "#F4EDE4",
    textColor: "#777777",
    btnTxtColor: "#ffffff",
    primary: "#36C5F0",
    containerColor: "#4A154B",
    mainPanelColor: "#ffffff",
    inputBg: "#f5f5f5",
    inputSpan: "rgba(0,0,0, 0.4)",
    inputTxtColor: "777777"
}

export const lightTheme = {
    backgroundColor: "#f0f0f0",
    textColor: "#ff88cc",
    btnTxtColor: "#ffffff",
    primary: "#00ADFF",
    containerColor: "#fbfbfb",
    mainPanelColor: "#ffffff",
    inputBg: "#f5f5f5",
    inputSpan: "rgba(0,0,0, 0.4)",
    inputTxtColor: "rgba(0,0,0, 0.4)"
}