import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props: any) => props.theme.backgroundColor};
    color: ${(props: any) => props.theme.textColor};;
  }

  .panelMain {
      background-color: ${(props: any) => props.theme.mainPanelColor};
  }

  .modal-content {
        background-color: ${(props: any) => props.theme.mainPanelColor};
  }

  .nav-link {
      color: rgba(255,255,255,0.5);
  }

  .nav-link:hover {
    color: rgba(255,255,255,0.75);
  }

  .nav-link.active {
    color: #ffffff;
  }

  input {
    background-color: ${(props: any) => props.theme.inputBg};
    color: ${(props) => props.theme.inputTxtColor};
  }

  select {
    background-color: ${(props: any) => props.theme.inputBg};
    color: ${(props) => props.theme.inputTxtColor};
  }

  .panelMain, .modal-body {
    span {
      color: ${(props: any) => props.theme.inputSpan};
    }
  } 

  .aTxt {
    color: rgba(255,255,255,0.25);
  }

  .aTxt:hover {
    color: rgba(255,255,255,0.75);
  }
`;

export const SytledH1 = styled.h1`
  color: ${(props: any) => props.theme.primary};
`;

export const StyledButton = styled.button`
  color: ${(props) => props.theme.btnTxtColor};
  background-color: ${(props) => props.theme.primary};
  box-shadow: none;
  border: 0px;
  border-radius: 5px;
  font-size: 16px;
  min-width: 150px;
  min-height: 20px;
  padding: 10px;
  margin: 5px auto;
`;


export const MainC = styled.div`
  height: 500px;
  width: 800px;
  display: flex;
  flex-direction: row;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  background-color: ${(props) => props.theme.containerColor};
`;