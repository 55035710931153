import React from 'react';
import './styles/styles.scss';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';

import { GlobalStyle } from './themes/themeComponents';

// import Top from './components/top';
import Main from './components/main';
// import Bottom from './components/bottom';

function App() {
  const theme = useSelector((state: any) => state.theme);

  return (
    <ThemeProvider theme={theme}>

      <GlobalStyle/>

        {/* <Top /> */}
        <Main />
        {/* <Bottom /> */}

    </ThemeProvider>
  );
}

export default App;
