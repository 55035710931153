var UserProfile = (function() {
    var username = "";
    var id = "";
    var avatar = "";

    var email = "";
    var license = "";
    var plan = "";
    var renewal = "";
    var cancelled = "";
    var card = "";

    var setDiscord = function(id2, username2, avatar2) {
        id = id2;
        username = username2;
        avatar = avatar2;
    }

    var setUser = function(email2, licenseKey, planDesc, renewalTime, isCancelled, cardInfo) {
        email = email2;
        license = licenseKey;
        plan = planDesc;
        renewal = renewalTime;
        cancelled = isCancelled;
        card = cardInfo;
    }

    var getUsername = function() {
        return username;
    }
    var getID = function() {
        return id;
    }
    var getAvatar = function() {
        return avatar;
    }
    var getLicense = function() {
        return license;
    }
    var getPlan = function() {
        return plan;
    }
    var getRenewal = function() {
        return renewal;
    }
    var getCancel = function() {
        return cancelled;
    }
    var getCard = function() {
        return card;
    }
    var getEmail = function() {
        return email;
    }
    var reset = function() {
        username = "";
        id = "";
        avatar = "";
        email = "";
        license = "";
        plan = "";
        renewal = "";
        cancelled = "";
        card = "";
    }

    return {
        setDiscord: setDiscord,
        setUser: setUser,
        getUsername: getUsername,
        getID: getID,
        getAvatar: getAvatar,
        getLicense: getLicense,
        getPlan: getPlan,
        getRenewal: getRenewal,
        getCancel: getCancel,
        getCard: getCard,
        getEmail: getEmail,
        reset: reset
    }
})();

export default UserProfile;