import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import BillingPayment from './billingPayment';
import BillingEmail from './billingEmail';

import { StyledButton } from '../themes/themeComponents';

import logo from "../logo.svg";

import UserProfile from "./UserProfile";

class MemberPanel extends React.Component {

    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
        this.cancelMembership = this.cancelMembership.bind(this);
        this.cancelMembershipHelper = this.cancelMembershipHelper.bind(this);
        this.state = {
            submitting: false
        }
    }

    cancelMembershipHelper() {
        fetch("/cancel/confirmed")
        .then(res => res.json())
        .then((result) => {
            alert(result.message);
            window.location.reload(false);
        })
    }

    cancelMembership() {
        this.setState({
            submitting: true
        }, this.cancelMembershipHelper)
    }

    logout() {
        fetch(`/logout`)
        .then(res => this.props.onLoginChange(false))
        .catch((err) => {})
    }

    render() {
        var renewalDate = new Date(parseInt(UserProfile.getRenewal())*1000);
        return (

            <Tab.Container id="memberPanel" defaultActiveKey="license">
    
                <div className="panelSide">
                    <img src={logo} alt="logo" className="panelLogo" /> 
                    <Nav>
                        <Nav.Item>
                            <Nav.Link eventKey="license"><span>#</span> license</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="billing"><span>#</span> billing</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                            <Nav.Link eventKey="referral"><span>#</span> referral</Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                            <Nav.Link eventKey="settings"><span>#</span> settings</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <button className="sideLogoutBtn" onClick={() => this.logout()} >Logout</button>
                </div>
                <div className="col panelMain">
                    <div className="userInfo">
                        <img src={`https://cdn.discordapp.com/avatars/${UserProfile.getID()}/${UserProfile.getAvatar()}.png`} className="userAvi" alt="user avi" />
                        <span className="userName">{UserProfile.getUsername()}</span>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="license">
                            <div className="pane-main">
                                <div className="row"><span>plan</span>{UserProfile.getPlan()}</div>
                                <div className="row"><span>license</span>{UserProfile.getLicense()}</div>
                                <div className="row"><span>{UserProfile.getCancel() ? "expires" : "renewal"}</span>{(renewalDate.getMonth() + 1) + "/" + renewalDate.getDate() + "/" + renewalDate.getFullYear()}</div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="billing">
                            <div className="pane-main">
                                <BillingPayment />
                                <BillingEmail />
                            </div>
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="referral">
                            <div className="pane-main">
                                <div className="row"><span>code</span>vM7dlEqyv8</div>
                                <div className="row"><span>value</span>$5</div>
                                <div className="row"><span>earned</span>$55</div>
                            </div>
                        </Tab.Pane> */}
                        <Tab.Pane eventKey="settings">
                            <div className="pane-main">
                                {/* <div className="row"><span>webhook</span><input name="webhook" placeholder="https://discordapp.com/api/webhooks/7580877601/4uBSZqcW11bb0JDmw6vm_3cZvfcekN6G3aABjgueE"></input></div> */}
                                {UserProfile.getCancel() ? "Update card to renew membership" : this.state.submitting ?  "Please wait..." : <StyledButton onClick={this.cancelMembership}>cancel membership</StyledButton>}
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
    
            </Tab.Container>
    
    
    
    
    
    
    
    
    
        );
    }
}

export default MemberPanel;