import React from 'react';
import { StyledButton } from '../themes/themeComponents';
import logo from "../logo.svg";

class Password extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {password: ''};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({password: event.target.value});
    }
    handleSubmit(event) {
        // alert("You submitted " + this.state.password);
        event.preventDefault();
        fetch("/password?password=" + this.state.password)
        .then(res => res.json())
        .then((result) => {
            if(result.error !== "password") {
                this.props.onPasswordEntry(true);
            }
        }, (error) => {
            console.log(error)
        });
    }

    render() {
        return (
            <div className="gateC">
                <div className="gateMain">
                    <img src={logo} alt="logo" className="gateLogo" /> 
                    <div className="gateInfo">Please enter a password to access our membership dashboard.</div>
                    <form onSubmit={this.handleSubmit} className="loginForm">
                    <input type='text' value={this.state.password} onChange={this.handleChange}></input>
                    {/* <input type="submit" value="Enter" style={{backgroundColor: "#5090b0"}}/> */}
                    <StyledButton type="submit" value="Enter">Enter</StyledButton>
                    </form>
                    {/* <StyledButton onclick={this.handleSubmit}>Enter</StyledButton> */}
                </div>
            </div>
        );
    }
}

export default Password;