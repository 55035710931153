import React from 'react';

import Modal from 'react-bootstrap/Modal';

import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UserProfile from "./UserProfile";

class BillingPayment extends React.Component {

    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.formattedCC = this.formattedCC.bind(this);
        this.submitPayment = this.submitPayment.bind(this);
        this.state = {
            show: false,
            name: "",
            card: "",
            expMo: "01",
            expYr: "2020",
            cvv: "",
            submitting: false
        }
    }

    showModal() {
        this.setState({
            show: true
        })
    }
    hideModal() {
        this.setState({
            show: false
        })
    }

    handleChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        if(name === "cvv") {
            value = value.substring(0,3)
        } else if (name === "card") {
            value = value.replace(/\D/g,'').substring(0,19)
        }
        this.setState({
            [name]: value
        })
    }

    submitPayment() {
        fetch('/update/card', {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                fullname: this.state.name,
                cardnumber: this.state.card,
                expmonth: this.state.expMo,
                expyear: this.state.expYr,
                cvv: this.state.cvv
            })
        }).then(res => {
            // return res.text();
            return res.json()
        })
        .then((result) => {
            alert(result.message);
            window.location.reload(false);
            // console.log(result);
        }, (error) => {
            console.log(error)
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            submitting: true
        }, this.submitPayment)
    }

    formattedCC() {
        if(this.state.card.length <= 4) {
            return this.state.card
        }
        var cardString = this.state.card.substring(0,4);
        if(this.state.card.length <= 8) {
            return cardString + " " + this.state.card.substring(4);
        }
        cardString = cardString + " " + this.state.card.substring(4,8);
        if(this.state.card.length <= 12) {
            return cardString + " " + this.state.card.substring(8);
        }
        return cardString + " " + this.state.card.substring(8,12) + " " + this.state.card.substring(12)
    }


    render() {
        return (

            <div className="row">
                <span>card</span>{UserProfile.getCard()}<FontAwesomeIcon icon={faPen} className="btnIcon" onClick={this.showModal} />
                <Modal show={this.state.show} onHide={this.hideModal} centered>
                    <Modal.Body>
                        {/* <div className="row"><span>name</span><input name="billingName" placeholder="John Doe"></input></div>
                        <div className="row"><span>card</span><input name="card" type="number" placeholder="4242 4242 4242 4242"></input></div>
                        <div className="row" id="expRow"><span>exp</span><input name="expMo" type="number" placeholder="08"></input><input name="expYr" type="number" placeholder="2024"></input></div>
                        <div className="row"><span>cvv</span><input name="cvv" type="number" placeholder="123"></input></div>
                        <StyledButton>save</StyledButton> */}
                        <form onSubmit={this.handleSubmit}>
                        <div className="row"><span>name</span><input required name="name" placeholder="John Doe" value={this.state.name} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}></input></div>
                        <div className="row"><span>card</span><input required name="card" placeholder="4242 4242 4242 4242" value={this.formattedCC()} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}></input></div>
                        <div className="row" id="expRow">
                            <span>exp</span>
                            <select name="expMo" value={this.state.expMo} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                            <select name="expYr" value={this.state.expYr} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                                <option value="2032">2032</option>
                                <option value="2033">2033</option>
                                <option value="2034">2034</option>
                                <option value="2035">2035</option>
                                <option value="2036">2036</option>
                                <option value="2037">2037</option>
                                <option value="2038">2038</option>
                                <option value="2039">2039</option>
                            </select>
                        </div>
                        <div className="row"><span>cvv</span><input required name="cvv" value={this.state.cvv} type="number" placeholder="123" onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}></input></div>     
                        <div className="row"><span></span><input type="submit" value={this.state.submitting ? "Please wait..." : "Update"} className="styledBtn" disabled={this.state.submitting ? "disabled" : ""}/></div>
                    </form>
                    </Modal.Body>
                </Modal>
            </div>
                            
        );
    }
}

export default BillingPayment;