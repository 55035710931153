import React from 'react';

import Modal from 'react-bootstrap/Modal';

import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UserProfile from "./UserProfile";

class BillingEmail extends React.Component {

    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitInfo = this.submitInfo.bind(this);
        this.state = {
            show: false,
            email: "",
            submitting: false
        }
    }

    showModal() {
        this.setState({
            show: true
        })
    }
    hideModal() {
        this.setState({
            show: false
        })
    }

    handleChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        })
    }

    submitInfo() {
        fetch('/update/email', {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: this.state.email
            })
        }).then(res => {
            // return res.text();
            return res.json()
        })
        .then((result) => {
            alert(result.message);
            window.location.reload(false);
            // console.log(result);
        }, (error) => {
            console.log(error)
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            submitting: true
        }, this.submitInfo)
    }

    render() {
        return (
            <div className="row">
                <span>email</span>{UserProfile.getEmail()}<FontAwesomeIcon icon={faPen} className="btnIcon" onClick={this.showModal} />
                <Modal show={this.state.show} onHide={this.hideModal} centered>
                    <Modal.Body>
                        <form onSubmit={this.handleSubmit}>
                            <div className="row"><span>email</span><input required name="email" placeholder="johndoe@gmail.com" value={this.state.email} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}></input></div>
                            <div className="row"><span></span><input type="submit" value={this.state.submitting ? "Please wait..." : "Update"} className="styledBtn" disabled={this.state.submitting ? "disabled" : ""}/></div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
                            
        );
    }
}

export default BillingEmail;