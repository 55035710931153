import React from 'react';
import logo from "../logo.svg";

class Purchase extends React.Component {

    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.formattedCC = this.formattedCC.bind(this);
        this.submitPayment = this.submitPayment.bind(this);
        this.state = {
            plan: "12",
            name: "",
            email: "",
            card: "",
            expMo: "01",
            expYr: "2021",
            cvv: "",
            coupon: "",
            submitting: false
        }
    }

    logout() {
        fetch(`/logout`)
        .then(res => this.props.onLoginChange(false))
        .catch((err) => {})
    }

    handleChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        if(name === "cvv") {
            value = value.substring(0,4)
        } else if (name === "card") {
            value = value.replace(/\D/g,'').substring(0,19)
        }
        this.setState({
            [name]: value
        })
    }

    submitPayment() {
        fetch('/submit', {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                fullname: this.state.name,
                email: this.state.email,
                cardnumber: this.state.card,
                expmonth: this.state.expMo,
                expyear: this.state.expYr,
                cvv: this.state.cvv,
                coupon: this.state.coupon,
                productId: this.state.plan
            })
        }).then(res => {
            // return res.text();
            return res.json()
        })
        .then((result) => {
            alert(result.message);
            window.location.reload(false);
            // console.log(result);
        }, (error) => {
            console.log(error)
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            submitting: true
        }, this.submitPayment)
    }

    formattedCC() {
        if(this.state.card.length <= 4) {
            return this.state.card
        }
        var cardString = this.state.card.substring(0,4);
        if(this.state.card.length <= 8) {
            return cardString + " " + this.state.card.substring(4);
        }
        cardString = cardString + " " + this.state.card.substring(4,8);
        if(this.state.card.length <= 12) {
            return cardString + " " + this.state.card.substring(8);
        }
        return cardString + " " + this.state.card.substring(8,12) + " " + this.state.card.substring(12)
    }

    render() {
        return (
            <div className="gateC">
                <div className="gateMain" id="purchase">
                    <img src={logo} alt="logo" className="gateLogo" />
                    <form onSubmit={this.handleSubmit}>
                        <div className="row"><span>plan</span>
                        <select name="plan" value={this.state.plan} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}>
                            {/* <option value="10">Monthly Membership (Test) - $25</option>
                            <option value="11">Yearly Membership (Test) - $300</option> */}
                            {/* <option value="7">OneDollaPaDayPlaya - $1</option> */}
                            <option value="12">Monthly Membership - $40</option>
                            <option value="13">Yearly Membership - $400</option>
                        </select>
                        </div>
                        <div className="row"><span>name</span><input required name="name" placeholder="John Doe" value={this.state.name} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}></input></div>
                        <div className="row"><span>email</span><input required name="email" placeholder="johndoe@gmail.com" value={this.state.email} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}></input></div>
                        <div className="row"><span>card</span><input required name="card" placeholder="4242 4242 4242 4242" value={this.formattedCC()} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}></input></div>
                        <div className="row" id="expRow">
                            <span>exp</span>
                            <select name="expMo" value={this.state.expMo} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                            <select name="expYr" value={this.state.expYr} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                                <option value="2032">2032</option>
                                <option value="2033">2033</option>
                                <option value="2034">2034</option>
                                <option value="2035">2035</option>
                                <option value="2036">2036</option>
                                <option value="2037">2037</option>
                                <option value="2038">2038</option>
                                <option value="2039">2039</option>
                            </select>
                        </div>
                        <div className="row"><span>cvv</span><input required name="cvv" value={this.state.cvv} type="number" placeholder="123" onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}></input></div>
                        <div className="row"><span>coupon</span><input name="coupon" placeholder="ZAmkn4RnnqUJrZj" value={this.state.coupon} onChange={this.handleChange} disabled={this.state.submitting ? "disabled" : ""}></input></div>     
                        <div className="row"><span></span><input type="submit" value={this.state.submitting ? "Submitting..." : "Purchase"} className="styledBtn" disabled={this.state.submitting ? "disabled" : ""}/></div>
                    </form>
                    <button className="sideLogoutBtn" onClick={() => this.logout()} >Logout</button>
                </div>
            </div>
        );
    }
}

export default Purchase;