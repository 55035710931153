import React from 'react';
import { StyledButton } from '../themes/themeComponents';
import logo from "../logo.svg";
import UserProfile from "./UserProfile";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            oauth: "",
            loadingLogin: true
        }

        this.getOAuth = this.getOAuth.bind(this);
        this.openOAuth = this.openOAuth.bind(this);
        this.getCode = this.getCode.bind(this);
        this.logInWithCode = this.logInWithCode.bind(this);
        this.logInWithCookie = this.logInWithCookie.bind(this);
    }

    openOAuth() {
        if(this.state.oauth) {
            window.location.assign(this.state.oauth);
        }
    }

    logInWithCookie() {
        fetch(`/login`)
        .then(res => res.json())
        .then((result) => {
            this.setState({loadingLogin: false})
            if(result.token) {
                UserProfile.setDiscord(result.discord.id, result.discord.username, result.discord.avatar)
                if(result.user) {
                    UserProfile.setUser(result.user.email, result.user.license, result.user.plan, result.user.renewal, result.user.cancelled, result.user.card)
                }
                this.props.onLoginChange(true, result.user);
            }
        })
    }

    logInWithCode(code) {
        fetch(`/login?code=${code}`)
        .then(res => res.json())
        .then((result) => {
            this.setState({loadingLogin: false})
            if(result.token) {
                UserProfile.setDiscord(result.discord.id, result.discord.username, result.discord.avatar)
                if(result.user) {
                    UserProfile.setUser(result.user.email, result.user.license, result.user.plan, result.user.renewal, result.user.cancelled, result.user.card)
                }
                this.props.onLoginChange(true, result.user);
            }
        })
    }

    getCode() {
        let params = new URLSearchParams(window.location.search);
        let code = params.get('code')
        if(code) {
            this.logInWithCode(code);
        } else {
            this.logInWithCookie();
        }
    }
    getOAuth() {
        fetch("/oauth")
        .then(res => res.json())
        .then((result) => {
            this.setState({
                isLoaded: true,
                oauth: result.oauth
            })
        }, (error) => {
            //error occurred
        });
    }

    componentDidMount() {
        this.getCode();
        this.getOAuth();
    }

    render() {
        return (
            <div className="gateC">
                <div className="gateMain">
                    {
                        this.state.loadingLogin ? "Loading..." :  
                        <div className="gateDiv">
                            <img src={logo} alt="logo" className="gateLogo" /> 
                            <div className="gateInfo">Please login in order to access our membership dashboard.</div>
                            <StyledButton onClick={this.openOAuth}>Login</StyledButton>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default Login;