import React from 'react';

import { MainC } from '../themes/themeComponents';

import Login from './login';
import Password from './password';
import Purchase from './purchase';
import MemberPanel from './panelMember';
import UserProfile from "./UserProfile";

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.state = {
            loggedIn: false,
            isMember: false,
            passwordCheck: false
        }
    }

    componentDidMount() {
        this.checkPassword();
    }

    checkPassword() {
        if (!this.state.passwordCheck) {
            fetch("/password")
            .then(res => {
                return res.json()
            })
            .then((result) => {
                if(result.error === "password") {
                    this.setState({
                        passwordCheck: true,
                        passwordValidated: false
                    })
                } else {
                    this.setState({
                        passwordCheck: true,
                        passwordValidated: true
                    })
                }
            }, (error) => {
                //error occurred
                console.log(error)
            });
        }
    }

    handleLogin(isLoggedIn, hasMembership) {
        if (isLoggedIn === false) {
            UserProfile.reset()
            this.setState({
                loggedIn: false,
                isMember: false,
                passwordCheck: false
            }, this.checkPassword)
        } else {
            this.setState({
                loggedIn: true,
                isMember: hasMembership
            });
        }
    }

    handlePassword(e) {
        this.setState({passwordValidated: e})
    }
   
    render() {
        const loggedIn = this.state.loggedIn;
        const needsPassword = this.state.passwordValidated === false;
        return (
            <div className="mainS">
                <div className="mainC">
                    {!this.state.passwordCheck ? "Loading..." : 
                        needsPassword ? <Password onPasswordEntry={this.handlePassword}/> : 
                        (loggedIn ? (this.state.isMember ? <MemberPanel onLoginChange={this.handleLogin} /> : <Purchase onLoginChange={this.handleLogin} />) : <Login onLoginChange={this.handleLogin} />)
                    }
                    {}
                    {/* <Password /> */}
                    {/* <Purchase /> */}
                </div>
            </div>
        );
    }
}

export default Main;